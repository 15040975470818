import React from 'react'

import {
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Stack,
    Img,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";

import callIcon from "../../design-system/icons/Contact/call.svg";
import emailIcon from "../../design-system/icons/Contact/sms.svg";
import userIcon from "../../design-system/icons/Profile/profile.svg";
import moneyIcon from "../../design-system/icons/Profile/money-recive.svg";
import userCodeIcon from "../../design-system/icons/Profile/user-id.svg";
import personalcard from "../../design-system/icons/Business/personalcard.svg";
import receiptsearch from '../../design-system/icons/Money/receipt-search.svg';

export default function ModalPersonalInfo(props) {

    const { onClose } = useDisclosure();

    return (
        <Modal
            isOpen={props?.infoModal?.show}
            onClose={onClose}
            size={"lg"}
            isCentered>
            <ModalOverlay />
            <ModalContent
                borderRadius="0.75rem"
                fontFamily={"Open Sans"}
                flexDir={"row"}>
                <ModalCloseButton
                    _focus={{ boxShadow: "rgba(0,0,0,0)" }}
                    onClick={(e) => props?.handleChangeInfoModal()}
                />
                <ModalBody
                    borderRadius={"0.75rem"}
                    m={"1.25rem"}
                    p={"1.3rem 0 1rem 1rem"}>
                    <H5 mb={"1.5rem"}>
                        {props?.infoModal?.item?.name} {props?.infoModal?.item?.last_name}
                        {/* {props?.infoModal?.item?.subTitle && <Text fontSize={"md"}>CUIT {props?.infoModal?.item?.subTitle}</Text>} */}
                    </H5>
                    <Stack
                        spacing={3}
                        alignItems={"flex-start"}
                        textAlign={"left"}
                        rowGap={2}
                    >
                        {props?.infoModal?.item?.name && (
                            <Flex>
                                <Img src={userIcon} alt={"user"} mr="2"></Img>
                                <Text fontSize={"md"}> Razón social: {props?.infoModal?.item?.title}</Text>
                            </Flex>
                        )}

                        {props?.infoModal?.item?.subTitle && (
                            <Flex>
                                <Img src={personalcard} alt={"cuit"} mr="2"></Img>
                                <Text
                                    fontSize={"md"}
                                    style={{
                                        color: "#515151",
                                    }}>
                                    {props?.infoModal?.item?.subTitle}
                                </Text>
                            </Flex>
                        )}

                        {props?.infoModal?.item?.email && (
                            <Flex>
                                <Img src={emailIcon} alt={"email"} mr="2"></Img>
                                <Text
                                    fontSize={"md"}
                                    style={{
                                        color: "#515151",
                                    }}>
                                    {props?.infoModal?.item?.email}
                                </Text>
                            </Flex>
                        )}

                        {props?.infoModal?.item?.phone && (
                            <Flex>
                                <Img src={callIcon} alt={"phone_number"} mr="2"></Img>
                                <Text
                                    fontSize={"md"}
                                    style={{
                                        color: "#515151",
                                    }}>
                                    {props?.infoModal?.item?.phone}
                                </Text>
                            </Flex>
                        )}

                        {props?.infoModal?.item?.iibb && (
                            <Flex>
                                <Img src={moneyIcon} alt={"iibb"} mr="2"></Img>
                                <Text
                                    fontSize={"md"}
                                    style={{
                                        color: "#515151",
                                    }}>
                                    {props?.infoModal?.item?.iibb}
                                </Text>
                            </Flex>
                        )}

                        {props?.infoModal?.item?.internal_code && (
                            <Flex>
                                <Img
                                    src={userCodeIcon}
                                    alt={"internal_code"}
                                    mr="2"></Img>
                                <Text
                                    fontSize={"md"}
                                    style={{
                                        color: "#515151",
                                    }}>
                                    {props?.infoModal?.item?.internal_code}
                                </Text>
                            </Flex>
                        )}

                        {props?.infoModal?.item?.condIva !== undefined &&  (
                            <Flex>
                                <Img
                                    src={receiptsearch}
                                    alt={"cond_iva"}
                                    mr="2"></Img>
                                <Text
                                    fontSize={"md"}
                                    style={{
                                        color: "#515151",
                                    }}>
                                    Condición frente al IVA: {props?.infoModal?.item?.condIva ? "Monotributista" : "Responsable Inscripto"}
                                </Text>
                            </Flex>
                        )}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}