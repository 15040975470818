import React, { useState, useEffect } from "react";
import "./Searcher.scss";
import {
  Flex,
  Input,
  InputRightElement,
  InputGroup,
  Button,
} from "@chakra-ui/react";
import search from "../../design-system/icons/Search/search.svg";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import useQuery from "../../hooks/useQuery";
import { useLocation } from "react-router-dom";
import { INITIAL_EMPTY_FILTERS } from "../../constants/constants";
// import TagManager from "react-gtm-module";

const Searcher = ({ history, ...props }) => {
  const query = useQuery();
  const term = query.get("term");
  const [value, setValue] = useState(term || "");

  const storageFilters = INITIAL_EMPTY_FILTERS;
  const location = useLocation();

  const handleSearch = (e) => {
    e.preventDefault();
    if (value) {
      history.push(`/search/?page=1&per_page=20&term=${value.toLowerCase()}`);
      localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
      // TagManager.dataLayer({
      //   dataLayer: { event: "search", path: value },
      // });
    }
  };

  useEffect(() => {
    setValue(term || "");
  }, [term, location.key]);

  return (
    <Flex>
      <form onSubmit={handleSearch} style={{ width: "100%" }}>
        <InputGroup>
          <Input
            value={value}
            placeholder="¿Qué estás buscando?"
            onChange={(event) => {
              setValue(event.target.value);
            }}
            bg={"brand.white"}
            h={50}
            borderRadius={12}
            _focus={{
              boxShadow:
                "0 0 0.0625rem 0.125rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
            }}
            autoFocus={!term}
            overflow="hidden"
          />

          <InputRightElement
            bg={"brand.acents"}
            borderRightRadius={12}
            h={50}
            w={"fit-content"}
            p={0}
          >
            <Button
              onClick={handleSearch}
              variant={"ghost"}
              _hover={{ bg: "brand.transparent" }}
              _active={{ bg: "brand.transparent" }}
              _focus={{
                boxShadow: "rgba(0,0,0,0)",
              }}
            >
              <BekeuIcon src={search} />
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
    </Flex>
  );
};

export default Searcher;
