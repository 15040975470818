import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import moment from "moment";

import { Flex, Grid, GridItem, Text, Img } from "@chakra-ui/react";

import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";

import { Paginator } from "../../components/Paginator/Paginator";
import { ApproveCartsBreadcrumb } from "../../components/ApproveCartsBreadcrumb/ApproveCartsBreadcrumb";
import ApproveCartsItemLoader from "../../components/ApproveCartsItem/ApproveCartsItemLoader";
import ApproveCartsOrdersItem from "../../components/ApproveCartsItem/ApproveCartsOrdersItem";

import { actions as cartActions } from "./ducks/approveCarts.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";

import search from "../../design-system/icons/Search/search-normal.svg";
import calendarIcon from "../../design-system/icons/Essentials/calendar.svg";

const ApproveCartsPage = ({
  listApprovalCarts,
  approveCart,
  rejectCart,
  approveCartOrder,
  rejectCartOrder,
  approvalresult,
  rejectResult,
  features,
  ...props
}) => {
  const [filteredCarts, setFilteredCarts] = useState([]);
  const [d, setD] = useState(null);
  const [date, setDate] = useState({});
  const [state, setState] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [searchParamInput, setSearchParamInput] = useState("");
  const [search_param, setSearchParam] = useState("");
  
  const history = useHistory();

  const cartsApproval = useSelector((state) => state.carts_approval);

  const arr = [...new Array(cartsApproval.cartsPagination.totalPages)];

  useEffect(() => {
    handleListApprovalCarts();
  }, [currentPage, state, search_param, d]);

  useEffect(() => {
    if (cartsApproval?.carts && cartsApproval?.carts.length > 0) {
      setFilteredCarts(cartsApproval?.carts);
    } else {
      setFilteredCarts([]);
    }
  }, [cartsApproval.carts]);

  const handleApproveCart = (item) => {
    approveCart(item);
  };

  const handleRejectCart = (item) => {
    rejectCart(item);
  };

  const handleApproveCartOrder = (item) => {
    approveCartOrder(item);
  };

  const handleRejectCartOrder = (item) => {
    rejectCartOrder(item);
  };

  const handleListApprovalCarts = () => {
    listApprovalCarts({
      page: currentPage,
      perPage,
      state,
      search_param,
      date,
    });
  }

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });

  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment().add(-24, "M").format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment().startOf("month").format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment().add(-3, "M").format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment().add(-6, "M").format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment().add(-12, "M").format("YYYY-MM-DD");
    }
    
    setDate({
      from: newDate,
      to: item.to,
      d: item.d,
    });

    setD(item.d.toString());

    setCurrentPage(1);
  };

  const handleChangeDate = (id, obj) => {
    setMoment(obj);
  }

  const handleChangeState = (id, obj) => {
    setState(obj.state);
    setCurrentPage(1);
  } 

  const handleChangeSearchParam = (value) => {
    setSearchParamInput(value)

    if(value.length > 2){
      setSearchParam(value);
      setCurrentPage(1);
      handleListApprovalCarts();
      return
    } 
    if(value.length === 0){
      setSearchParam("");
      setCurrentPage(1);
      handleListApprovalCarts();
      return
    }
  };

  const handleChangePagination = (pageNumber) => {
    if(pageNumber === currentPage) return;
    setCurrentPage(pageNumber);
  }

  return (
    <Flex justifyContent={"center"} px={["1rem"]}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"2.5rem"}
        bg={"brand.background"}
        maxW="75rem"
        w={"100%"}
      >
        <ApproveCartsBreadcrumb />

        <H5 mt={"2rem"}>Aprobar compras</H5>
        <Grid
          templateColumns={["unset", "unset", "repeat(2,1fr)", "2fr repeat(3,1fr)"]}
          mt={"2rem"}
          spacing={4}
          gap={"1rem"}
        >
          <GridItem>
            <InputBekeu
              placeholder={"Buscar"}
              bg={"white"}
              lefticon={<Img src={search} mr={4} />}
              w={"100%"}
              value={searchParamInput}
              onChange={(e) => handleChangeSearchParam(e.target.value)}
            />
          </GridItem>

          <GridItem>
            <MenuBekeu
              height={"3.5rem"}
              mr={"1rem"}
              zIndex={9999}
              customOnClick={handleChangeState}
              items={[
                { name: "Todos", state: "0" },
                { name: "Pendiente", state: "1" },
                { name: "Aprobada", state: "2" },
                { name: "Rechazada", state: "3" },
              ]}
            >
              {state && state === "0"
                ? "Todos"
                : state === "1"
                  ? "Pendiente"
                  : state === "2"
                    ? "Aprobada"
                    : state === "3"
                      ? "Rechazada"
                      : "Estado"}
            </MenuBekeu>
          </GridItem>
          {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
          {/* <MenuBekeu
            disabled={true}
            height={"3.5rem"}
            mr={"1rem"}
            items={[
              { name: "felipe@empresa.com.ar", user: "felipe@empresa.com.ar" },
              {
                name: "usuario@empresa.com.ar",
                user: "usuario@empresa.com.ar",
              },
              {
                name: "usuario@empresa.com.ar",
                user: "usuario@empresa.com.ar",
              },
            ]}
          >
            Usuario
          </MenuBekeu> */}
          <GridItem>
            <MenuBekeu
              height={"3.5rem"}
              leftIcon
              leftCustomIcon={calendarIcon}
              setMoment={setMoment}
              state={state}
              customOnClick={handleChangeDate}
              items={[
                {
                  name: "Todas",
                  date: "Todas",
                  to: formattedDate,
                  d: 0,
                },
                {
                  name: "Este mes",
                  date: "Este mes",
                  to: formattedDate,
                  d: 1,
                },
                {
                  name: "Últimos 3 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 3,
                },
                {
                  name: "Últimos 6 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 6,
                },
                {
                  name: "Últimos 12 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 12,
                },
              ]}
            >
              {d
              ? (d === "0" || d === 0)
                ? "Todas"
                : d === "1"
                  ? "Este mes"
                  : d === "3"
                    ? "Últimos 3 meses"
                    : d === "6"
                      ? "Últimos 6 meses"
                      : d === "12" && "Últimos 12 meses"
              : "Fecha"}
            </MenuBekeu>
          </GridItem>

          <GridItem>
            <Flex w={"100%"}>
              <ButtonBekeuOutline
                onClick={() => history.push("/approve-orders/delegate-orders")}
                bg={"brand.background"}
                height={"3.5rem"}
                disabled={true}
              >
                Delegar aprobaciones
              </ButtonBekeuOutline>
            </Flex>
          </GridItem>
        </Grid>
        {!cartsApproval.approvalLoading ? (
          <>
            {filteredCarts && filteredCarts.length > 0 ? (
              filteredCarts.map((cart, id) => {
                return (
                  <ApproveCartsOrdersItem
                    mt={"2rem"}
                    type={cart.state}
                    key={id}
                    item={cart}
                    state={cart?.state === "1" || cart?.state === "0"}
                    handleApproveCart={handleApproveCart}
                    handleRejectCart={handleRejectCart}
                    handlePagination={handleListApprovalCarts}
                    features={features}
                  />
                );
              })
            ) : (
              <Text mt={6} className="animate__animated animate__fadeIn">
                No se encontraron pedidos{" "}
                {state && state === "0"
                  ? "para esta búsqueda"
                  : state === "1"
                    ? "pendientes"
                    : state === "2"
                      ? "aprobados"
                      : state === "3"
                        ? "rechazados"
                        : "pendientes de aprobación"}
              </Text>
            )}
            {!cartsApproval.approvalLoading &&
              cartsApproval &&
              cartsApproval?.carts?.length > 0 &&
              cartsApproval.cartsPagination.totalPages > 1 && (
                <Paginator
                  totalItems={cartsApproval.cartsPagination.totalPages}
                  itemsArray={arr}
                  perPage={5}
                  mt={"1.75rem"}
                  customOnClick={handleChangePagination}
                  currentPage={currentPage}
                />
              )}
          </>
        ) : (
          [1, 2].map((cart, index) => (
            <ApproveCartsItemLoader mt={"2rem"} key={index} cart={cart} />
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...cartActions, ...product.actions })(ApproveCartsPage),
);
