import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment";
import "moment/locale/es-mx";

import { Divider, Flex, Img, Text, Grid, Tooltip } from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import notFoundImg from "../../design-system/images/not-found.png";

import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";

import { ApproveCartsRejectModal } from "../ApproveCartsRejectModal/ApproveCartsRejectModal";
import { ApproveCartsSuccessModal } from "../ApproveCartsSuccessModal/ApproveCartsSuccessModal";
import { ApproveCartsBadge } from "../ApproveCartsBadge/ApproveCartsBadge";
import { CartOrderId } from "../CartOrderId/CartOrderId";
import { MyOrdersItemBadge } from "../MyOrdersItemBadge/MyOrdersItemBadge";
import { MaxDeliveryTimeBadge } from "../MaxDeliveryTimeBadge/MaxDeliveryTimeBadge";

import useFormatCurrency from "../../hooks/useFormatCurrency";
import useFeatures from "../../hooks/useFeatures";

import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import * as cartActions from "../../pages/approveCartsPage/ducks/approveCarts.duck";

import TotalARSUSD from "../Common/TotalARSUSD";

const DetailItem = ({ orderItem, itemArr, handleNoProduct, noProduct }) => {
  const [imageError, setImageError] = useState(false);
  const [newDeliveryArray, setNewDeliveryArray] = useState([]);
  // const [maxDeliveryDay, setMaxDeliveryDay] = useState(0);

  const { ARS, USD } = useFormatCurrency();
  const { proUser, imputationUser } = useFeatures();

  useEffect(() => {
    itemArr &&
      itemArr[0]?.items?.map((orderItemVal) => {
        return {
          buy_order_item_id: orderItemVal?.id,
          qty_sent: orderItemVal?.quantity - orderItemVal?.quantity_sent,
          delivery_time: orderItemVal?.days_delivery_time_block,
        };
      })
  }, [itemArr]);

  useEffect(() => {
    if (orderItem && orderItem?.items) {
      const deliveryArray = orderItem?.items.reduce((acc, item) => {
        if (!acc[item?.days_delivery_time_block]) {
          acc[item?.days_delivery_time_block] = {
            days_delivery_time_block: [
              {
                ...item,
                days_delivery_time_block: item.days_delivery_time_block,
              },
            ],
          };
        } else {
          acc[item?.days_delivery_time_block]?.days_delivery_time_block.push({
            ...item,
            days_delivery_time_block: item.days_delivery_time_block,
          });
        }
        return acc;
      }, {});

      // const maxDays = Math.max(
      //   ...Object.keys(deliveryArray).map((a) => Number(a)),
      // );
      // setMaxDeliveryDay(deliveryArray[maxDays]);

      setNewDeliveryArray(Object.values(deliveryArray));
    }
  }, []);

  return (
    <Flex flexDir={"column"} justifyContent={"center"} alignItems="center">
      <BoxBekeu
        width={["100%", "100%", "100%", "95%"]}
        my={"3rem"}
        boxShadow="none"
        border="0.0313rem solid"
        borderColor="brand.border"
        p={0}
        overflowX={"auto"}
      >
        <Flex flexDir={"column"}>
          <Flex alignItems={"center"} justifyContent={"space-between"} p="1.5rem">
            <ProductDetailModalSeller
              sellerName={orderItem.provider?.fantasy_name}
              email={orderItem.provider?.email}
              phone={orderItem.provider?.phone}
            />
            <MyOrdersItemBadge item={orderItem}>
              {orderItem?.state === "1"
                ? "Pendiente"
                : orderItem?.state === "2"
                  ? "Entrega en proceso"
                  : orderItem?.state === "2.1"
                    ? "Enviada por proveedor"
                    : orderItem?.state === "3"
                      ? "Rechazada"
                      : orderItem?.state === "3.1"
                        ? "Rechazada por aprobador"
                        : orderItem?.state === "3.2"
                          ? "Rechazada por proveedor"
                          : orderItem?.state === "3.3"
                            ? "Rechazada por ERP"
                            : orderItem?.state === "3.4"
                              ? "Rechazada por sistema"
                              : orderItem?.state === "4"
                                ? "Confirmada"
                                : orderItem?.state === "5"
                                  ? "Reclamada"
                                  : "Pendiente"}
            </MyOrdersItemBadge>
          </Flex>
          <Divider />
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            px="1.5rem"
          >
            {orderItem?.currency_code && (
              <Text
                fontWeight={400}
                fontSize={"1rem"}
                color="brand.contentSecondary"
                py="1.5rem"
              >
                Importe en {orderItem?.currency_code}
              </Text>
            )}
             <Flex flexDir={["column", "column", "row", "row"]} gap={4}>
                {!proUser && orderItem?.erp_number && (
                  <Text
                    fontWeight={400}
                    fontSize={"1rem"}
                    border="0.0625rem solid"
                    borderColor={"brand.contentSecondary"}
                    padding={"0.5rem"}
                    borderRadius={"0.75rem"}
                    color="brand.contentSecondary"
                  >
                    N° de Pedido SAP: {orderItem?.erp_number}
                  </Text>
                )}
                {orderItem?.internal_code && (
                  <Text
                    fontWeight={400}
                    fontSize={"1rem"}
                    border="0.0625rem solid"
                    borderColor={"brand.contentSecondary"}
                    padding={"0.5rem"}
                    borderRadius={"0.75rem"}
                    color="brand.contentSecondary"
                  >
                    Código interno: {orderItem?.internal_code}
                  </Text>
                )}
            </Flex>
          </Flex>
          {newDeliveryArray.map((deliveryArray, id) => (
            <Flex flexDir={"column"} key={id}>
              <Divider />
              <MaxDeliveryTimeBadge
                days={
                  deliveryArray?.days_delivery_time_block[0]
                    ?.days_delivery_time_block
                }
                dates={moment(
                  deliveryArray?.days_delivery_time_block[0]
                    ?.date_delivery_time_block,
                ).format("DD/MM/YYYY")}
                m="1.5rem"
              />
              {deliveryArray?.days_delivery_time_block?.map((i, id) => (
                <div key={id}>
                  <Divider />
                  <Flex
                    justifyContent={"space-between"}
                    p="1.5rem"
                    gap="0.75rem"
                    w={"63rem"}
                  >
                    <Flex>
                      <Flex
                        w="5.5rem"
                        h="5.5rem"
                        mr="1.25rem"
                        border="0.0625rem solid"
                        borderColor={"brand.border"}
                        borderRadius={"0.75rem"}
                        overflow="hidden"
                        justifyContent={"center"}
                        alignItems="center"
                        gap="1.5rem"
                      >
                        <Img
                          src={
                            imageError
                              ? notFoundImg
                              : i?.combination?.media?.image_source ||
                                i?.data_combination?.image_source
                          }
                          onError={() => setImageError(true)}
                          draggable={"false"}
                          objectFit="cover"
                          cursor={imageError ? "not-allowed" : "pointer"}
                          onClick={() => handleNoProduct(i)}
                          title={
                            imageError
                              ? "Imagen no disponible"
                              : i?.combination?.title ||
                                i?.data_combination?.title
                          }
                          h={imageError ? "50%" : "100%"}
                        />
                      </Flex>
                      <Flex flexDir={"column"} gap="0.75rem">
                        <Flex flexDir={"column"}>
                          <H6
                            lineHeight={"1.5rem"}
                            _hover={{ color: "brand.hover" }}
                            transition="300ms ease all"
                            onClick={() => handleNoProduct(i)}
                            noOfLines={1}
                            cursor="pointer"
                            w={"20.3125rem"}
                            color={"brand.contentPrimary"}
                            fontWeight={600}
                          >
                            <Tooltip
                              bg={"white"}
                              color={"brand.contentPrimary"}
                              borderRadius={6}
                              p={2}
                              hasArrow
                              placement="top"
                              label={
                                i?.combination?.title ||
                                i?.data_combination?.title
                              }
                            >
                              {i?.combination?.title ||
                                i?.data_combination?.title}
                            </Tooltip>
                          </H6>
                          <H6>
                            {i?.currency_code === "USD"
                              ? USD.format(parseFloat(i?.price_unit))
                              : ARS.format(parseFloat(i?.price_unit))}{" "}
                            + Imp
                          </H6>
                        </Flex>
                        {proUser && (
                          <Flex gap="0.75rem">
                            <Paragraphs
                              lineHeight={"1.375rem"}
                              mb="0.1875rem"
                              fontWeight={600}
                            >
                              Tarifa de servicio:
                            </Paragraphs>
                            {i?.currency_code === "USD"
                              ? USD.format(parseFloat(i?.unit_service_fee))
                              : ARS.format(parseFloat(i?.unit_service_fee))}
                          </Flex>
                        )}

                        {imputationUser && (
                            <Flex flexDir={"column"}>
                              <Paragraphs
                                lineHeight={"1.375rem"}
                                mb="0.1875rem"
                                fontWeight={600}
                              >
                                Centros de costo:
                              </Paragraphs>
                              {i?.cost_centers?.map((item, key) => (
                                <Flex
                                  key={key}
                                  justifyContent={"space-between"}
                                  w="80%"
                                >
                                  <Paragraphs lineHeight={"1.375rem"}>
                                    {item?.cost_center}
                                  </Paragraphs>
                                  <Paragraphs lineHeight={"1.375rem"}>
                                    {item?.percentage}%
                                  </Paragraphs>
                                </Flex>
                              ))}
                            </Flex>
                          )}
                      </Flex>
                    </Flex>

                    <Grid templateColumns={["repeat(4, 1fr)"]} gap={"3.4rem"}>
                      <Flex
                        flexDir={"column"}
                        gap="0.75rem"
                        lineHeight={"1.375rem"}
                      >
                        <Text>Cantidad comprada</Text>
                        <Text>{i?.quantity}</Text>
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap="0.75rem"
                        lineHeight={"1.375rem"}
                      >
                        <Text>Cantidad ya enviada</Text>
                        <Text>{i?.quantity_sent}</Text>
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap="0.75rem"
                        lineHeight={"1.375rem"}
                      >
                        <Text>Cantidad cancelada</Text>
                        <Text>{i?.quantity_cancel}</Text>
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap="0.75rem"
                        lineHeight={"1.375rem"}
                      >
                        <Text>Cantidad confirmada</Text>
                        <Text>{i?.quantity_confirmed}</Text>
                      </Flex>
                    </Grid>
                  </Flex>
                </div>
              ))}
            </Flex>
          ))}          

          {noProduct && (
            <Text
              fontSize={"sm"}
              mb={4}
              className="animate__animated animate__fadeIn"
            >
              Este producto no se encuentra disponible para comprar nuevamente
            </Text>
          )}
          <Divider />
          <Flex
            flexDir={"column"}
            alignItems="flex-end"
            mt={"1rem"}
            gap="0.75rem"
            p="1.5rem"
          >
            {orderItem?.total_ARP && parseInt(orderItem?.total_ARP) !== 0 && (
              <>
                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Subtotal productos en ARS:</H6>{" "}
                  <H6 fontWeight={400}>
                    {ARS.format(parseFloat(orderItem?.total_ARP))} + Imp
                  </H6>
                </Flex>
                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Costo de envío en ARS:</H6>
                  {!orderItem?.shipping_cost_ARP ||
                  orderItem?.shipping_cost_ARP ? (
                    <H6 fontWeight={400} color="brand.success">
                      Incluído
                    </H6>
                  ) : (
                    <H6 fontWeight={400}>
                      {ARS.format(parseFloat(orderItem?.shipping_cost_ARP))} +
                      Imp
                    </H6>
                  )}
                </Flex>
                <H5>
                  Total en ARS{" "}
                  {ARS.format(parseFloat(orderItem?.total_w_shipping_ARP))} +
                  Imp
                </H5>
              </>
            )}
            {orderItem?.total_USD && parseInt(orderItem?.total_USD) !== 0 && (
              <>
                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Subtotal productos en USD:</H6>{" "}
                  <H6 fontWeight={400}>
                    {USD.format(parseFloat(orderItem?.total_USD))} + Imp
                  </H6>
                </Flex>
                <Flex justifyContent={"space-between"} gap={"1rem"}>
                  <H6 fontWeight={400}>Costo de envío en USD:</H6>
                  {!orderItem?.shipping_cost_USD ||
                  orderItem?.shipping_cost_USD ? (
                    <H6 fontWeight={400} color="brand.success">
                      Incluído
                    </H6>
                  ) : (
                    <H6 fontWeight={400}>
                      {ARS.format(parseFloat(orderItem?.shipping_cost_USD))} +
                      Imp
                    </H6>
                  )}
                </Flex>
                <H5>
                  Total en USD{" "}
                  {ARS.format(parseFloat(orderItem?.total_w_shipping_USD))} +
                  Imp
                </H5>
              </>
            )}
          </Flex>
        </Flex>
      </BoxBekeu>
    </Flex>
  );
};

const ApproveCartOrdersItem = ({
  item,
  type,
  showitems,
  selected,
  state,
  getOneProduct,
  handleApproveCart,
  handleRejectCart,
  handleApproveOrder,
  handleRejectOrder,
  handlePagination,
  shippingIncluded,
  params,
  ...props
}) => {
  const history = useHistory();
  const [noProduct, setNoProduct] = useState(false);

  const { proUser } = useFeatures();

  useEffect(() => {
    handleScrollTo();
  }, []);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  function handleNoProduct(i) {
    handleScrollTo();
    if (i.combination) {
      history.push(`/detail/${i?.sku}`);
    } else {
      setNoProduct(!noProduct);
    }
  }

  return (
    <BoxBekeu p={0} {...props}>
      <Flex flexDir={"column"}>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          p="1.5rem"
          flexDir={["column", "column", "row", "row"]}
          gap={[4, 4, "unset", "unset"]}
        >
          <Flex gap={2} flexDir={["column", "row"]}>
              <H6 fontWeight={600}>Fecha de compra:</H6>
              <Text fontSize={"1.125rem"}>
                {moment(item?.created_at).format("DD/MM/YYYY")}
              </Text>
          </Flex>
          <Flex flexDir={["column", "column", "row", "row"]} gap={4}>
            <CartOrderId h={"2.375rem"}>
              Nº de carrito: {item?.internal_code}
            </CartOrderId>

            <ApproveCartsBadge item={item}>
              {item?.state === "1"
                ? "Pendiente de aprobación"
                : item?.state === "2"
                  ? "Entrega en proceso"
                  : item?.state === "3"
                    ? "Rechazada"
                    : item?.state === "4"
                      ? "Finalizada"
                      : "En curso"}
            </ApproveCartsBadge>
          </Flex>
        </Flex>

        <Divider />

        <Flex
          justifyContent={"space-between"}
          flexDir={["column", "column", "row", "row"]}
          p={"1.5rem"}
        >
          <Flex flexDir={"column"} gap={3}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem">
              Comprador
            </Paragraphs>
            <Paragraphs lineHeight="1.5rem">
              {item?.client?.user?.email}
            </Paragraphs>
          </Flex>

          <Flex flexDir={"column"} gap={3}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem">
              Dirección de entrega
            </Paragraphs>
            <Paragraphs lineHeight="1.5rem">
              {item?.address_description}
            </Paragraphs>
          </Flex>

          <Flex flexDir={"column"} gap={3}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem">
              Comentarios del comprador
            </Paragraphs>
            <Paragraphs lineHeight="1.5rem">
              {item?.approver_comments || "-"}
            </Paragraphs>
          </Flex>
        </Flex>

        <TotalARSUSD item={item} shippingIncluded={shippingIncluded} proUser={proUser} />

        {item?.buyOrders &&
          item.buyOrders.map((orderItem, oid, itemArr) => (
            <DetailItem
              key={oid}
              orderItem={orderItem}
              history={history}
              handlePagination={handlePagination}
              itemArr={itemArr}
              handleScrollTo={handleScrollTo}
              noProduct={noProduct}
              handleNoProduct={handleNoProduct}
            />
          ))}

        <Flex flexDir={"column"} justifyContent={"center"} alignItems="center">
          <Flex flexDir={"column"}>
            {item?.state === "1" && (
              <Flex
              alignItems={"center"}
              justifyContent={"center"}
              p="1.5rem"
              w={"100%"}
            >
              <Flex textAlign={"center"} alignItems={"center"}>
                <Flex
                  alignItems={"center"}
                  flexDir={["column", "column", "row", "row"]}
                  gap={4}
                >
                  <ApproveCartsRejectModal
                    handleRejectCart={handleRejectCart}
                    item={item}
                    handlePagination={handlePagination}
                    px={"3.125rem"}
                    w={"14.375rem"}
                  />
                  <ApproveCartsSuccessModal
                    handleApproveCart={handleApproveCart}
                    item={item}
                    handlePagination={handlePagination}
                    px={"3.125rem"}
                    w={"14.375rem"}
                  />
                </Flex>
              </Flex>
            </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(
  connect(null, { ...product.actions, ...cartActions.actions })(
    ApproveCartOrdersItem,
  ),
);
