import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Text,
  SkeletonText,
  Flex,
  Grid,
  Img,
  Button,
  Stack,
  GridItem
} from "@chakra-ui/react";

import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { CheckboxBekeuInterminate } from "../../design-system/inputs/CheckboxInterminate";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import search from "../../design-system/icons/Search/search-normal.svg";
import FilterModalSummary from "./FilterModalSummary";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

import MenuFilter from "../Filter/MenuFilter";
import trashIcon from "../../design-system/icons/Essentials/trash.svg";

import arrowLight from "../../design-system/icons/Arrows/white-arrow-down.svg";
import arrowDark from "../../design-system/icons/Arrows/arrow-down.svg";
import arrowPrimary from "../../design-system/icons/Arrows/arrow-down-primary.svg";
import filterDark from "../../design-system/icons/Shapes/filter.svg";

import styles from "./ProductsGridFilterBox.module.css";
import { INITIAL_EMPTY_FILTERS } from "../../constants/constants";

const CURRENCY_CODES = [
  { id: 1, name: "ARS", value: "0" },
  { id: 2, name: "USD", value: "1" },
  { id: 3, name: "Ambos", value: null },
]

export const ProductsGridFilterBox = ({
  listProductsGridsFilters,
  skeletonLoading,
  allCategories,

  storageFilters,
  totalFiltersSelected,
  handleGetFilters,

  handleChangeStorageFilters
}) => {
  const productsGridState = useSelector(state => state?.productsGrid);
  //DEV-177 filters state
  const [categoriesWParents, setCategoriesWParents] = useState([]);
  const [collapseIndex, setCollapseIndex] = useState({ category: {} });
  const [providersFilter, setProvidersFilter] = useState([]);
  const [providersTypeFilter, setProvidersTypeFilter] = useState([]);
  const [priceMin, setPriceMin] = useState("");
  const [priceMax, setPriceMax] = useState("");
  const [currencyCode, setCurrencyCode] = useState(null);
  const [deliveryDaysMin, setDeliveryDaysMin] = useState(0);
  const [deliveryDaysMax, setDeliveryDaysMax] = useState(0);
  const [toggleFilterModal, setToggleFilterModal] = useState(false);
  const [searchParamInputDeliveryZone, setSearchParamInputDeliveryZone] = useState("");
  const [deliveryZonesFilter, setDeliveryZonesFilter] = useState([]);
  const [searchParamInputProviders, setSearchParamInputProviders] = useState("");
  useEffect(() => {
    setPriceMin(storageFilters?.price && storageFilters?.price[0]?.min ? storageFilters?.price[0]?.min : "");
    setPriceMax(storageFilters?.price && storageFilters?.price[0]?.max ? storageFilters?.price[0]?.max : "");
    setCurrencyCode(storageFilters?.currency_code);
    //Obtenemos cotización del dolar
    /*TODO: DEV-177 error de cors desde localhost. Probar en dev.
      En el caso de no funcionar, PROVISORIAMENTE, tomo valor desde dolarapi.com
    */
    /* const obtenerCotizacion = async () => {
      try {
        const cotizacion = await fetchCotizacion();
        setDolarQuoteBNA(cotizacion);
      } catch (error) {
        //Si hay un error tomamos el dolar de dolarapi.com
        try {
          fetch("https://dolarapi.com/v1/dolares/oficial")
          .then(response => response.json())
          .then(data => setDolarQuoteBNA(data?.venta));
        } catch (error) {
          console.error('Error al tomar cotización de dolares');
        }
      }
    };
    */

    // obtenerCotizacion();

  }, [storageFilters])

  useEffect(() => {
    //Categories
    if (!allCategories || allCategories.length === 0) {
      setCategoriesWParents([]);
    } else {
      listProductsGridsFilters?.categories &&
        setCategoriesWParents(findCategories(allCategories, listProductsGridsFilters?.categories))
    }

    if (listProductsGridsFilters?.providers && listProductsGridsFilters?.providers.length > 0) {
      const formattedProviders = listProductsGridsFilters?.providers.map(provider => (
        { name: provider?.fantasy_name, id: provider?.id }
      ))
      setProvidersFilter(formattedProviders);
    } else {
      setProvidersFilter([]);
    }

    if (listProductsGridsFilters?.providers_type && listProductsGridsFilters?.providers_type.length > 0) {
      setProvidersTypeFilter(listProductsGridsFilters?.providers_type);
    } else {
      setProvidersTypeFilter([]);
    }

  }, [listProductsGridsFilters, allCategories]);

  useEffect(() => {
    setDeliveryZonesFilter(
      listProductsGridsFilters?.regions.sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [listProductsGridsFilters?.regions])

  //Function to remove item from array
  function removeItemFromArr(arr, item) {
    const newArray = arr.filter((a) => a !== item);
    return newArray;

  }
  //Function to remove itemS from array
  function removeItemsFromArr(items, arr) {
    const _items = items.flatMap(it => it.id);
    const newArray = arr.filter((a) => !_items.includes(a));

    return newArray;
  }

  // function to scroll to top
  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  // START ----- setting category filter structure
  const buildCategoryIndex = (allCategories) => {
    const index = {};
    allCategories.forEach((category) => {
      category.sub_categories.forEach((subcategory) => {
        if (subcategory?.sub_category_items && subcategory?.sub_category_items.length > 0) {
          subcategory.sub_category_items.forEach((subSubCategory) => {
            index[subSubCategory.id] = {
              subSubCategoryId: subSubCategory.id,
              subSubCategoryName: subSubCategory.name,
              subcategoryId: subcategory.id,
              subcategoryName: subcategory.name,
              categoryId: category.id,
              categoryName: category.name,
            };
          });
        } else {
          index[subcategory.id] = {
            subSubCategoryId: null,
            subSubCategoryName: null,
            subcategoryId: subcategory.id,
            subcategoryName: subcategory.name,
            categoryId: category.id,
            categoryName: category.name,
          };
        }
      });
    });
    return index;
  };

  function findCategories(allCategories, filterCategories) {
    const categoryIndex = buildCategoryIndex(allCategories);

    const filteredCategories = filterCategories.map((filterItem) => categoryIndex[filterItem.id] || null);
    const filteredCategoriesNotNulls = filteredCategories.filter(filterItem => filterItem !== null);
    // Agrupar por subCategoryId
    const groupBy = filteredCategoriesNotNulls.reduce((acc, item) => {
      if (item) {
        const { subcategoryId, subcategoryName, categoryId, categoryName } = item;

        // Si el categoryId no existe en el acumulador, lo inicializamos
        if (!acc[categoryId]) {
          acc[categoryId] = {
            id: categoryId,
            name: categoryName,
            subCategories: {},
          };
        }

        // Si el subcategoryId no existe en el objeto de subcategorías, lo inicializamos
        if (!acc[categoryId].subCategories[subcategoryId]) {
          acc[categoryId].subCategories[subcategoryId] = {
            id: subcategoryId,
            name: subcategoryName,
            subSubCategories: []
          };
        }

        // Añadimos la sub-subcategoría al array correspondiente de esa subcategoría
        acc[categoryId].subCategories[subcategoryId].subSubCategories.push({
          id: item.subSubCategoryId,
          name: item.subSubCategoryName
        });

        return acc;
      }
    }, {});

    return Object.values(groupBy);
  }
  // END ----- setting category filter structure

  const handleChangeCollapseIndex = (type, id) => {
    setCollapseIndex(prevState => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [id]: prevState[type] && prevState[type][id] !== undefined
          ? !prevState[type][id]
          : true
      },
    }));
  }

  // function for setting localStorage state
  function setGlobalStorage(reset) {
    localStorage.setItem(
      "globalStorage",
      JSON.stringify({
        isFirstLoad: true,
        page: !reset && 1,
      })
    );
  }

  //Category
  function handleChangeFilters(filter, reset = false) {

    //Reiniciamos filtro
    if (reset) {
      handleChangeStorageFilters({
        ...storageFilters,
        isFirstLoad: false,
        resetPage: true,
        categories: {
          category_name: [],
          category_id: [],
          categoriesTag: [],
        },
        sort_by: storageFilters?.sort_by
      });
      handleScrollTo();
      return;
    }

    //Quitamos category_id del filtro
    const localStorageFilters = JSON.parse(localStorage.getItem("storageFilters"));
    let filters = Array.isArray(filter) ? filter.filter(fl => localStorageFilters?.categories?.category_id.includes(fl.id)) : [];

    if (filters.length > 0 || localStorageFilters?.categories?.category_id?.includes(filter.id)) {
      handleChangeStorageFilters({
        ...localStorageFilters,
        isFirstLoad: false,
        resetPage: true,
        categories: {
          category_id:
            Array.isArray(filter)
              ? removeItemsFromArr(filters, localStorageFilters.categories?.category_id) //eiminar masivo 
              : removeItemFromArr(localStorageFilters.categories?.category_id, filter.id), //eliminar de a uno
        },
      });
    } else { //Agregamos category_id al filtro

      const newCategoryId = [
        ...localStorageFilters?.categories?.category_id,
        (Array.isArray(filter) ? filter.map(item => item.id) : [filter.id]),
      ].flat();

      handleChangeStorageFilters({
        ...localStorageFilters,
        resetPage: true,
        categories: {
          category_name: [],
          category_id: newCategoryId,
          categoriesTag: [],
        }
      });

      handleScrollTo();
    }
  }

  //Brand
  function handleChangeBrand(filter, reset = false) {
    //Reiniciamos filtro
    if (reset) {
      handleChangeStorageFilters({
        ...storageFilters,
        brands: {
          brand_name: [],
          brand_id: [],
          brandsTag: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });


      handleScrollTo();
      return;
    }

    //Quitamos brand_id del filtro
    if (storageFilters?.brands?.brand_id.includes(filter.id)) {
      handleChangeStorageFilters({
        ...storageFilters,
        brands: {
          brand_name: removeItemFromArr(storageFilters.brands?.brand_name, filter.name),
          brand_id: removeItemFromArr(storageFilters.brands?.brand_id, filter.id),
          brandsTag: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });
    } else { //Agregamos brand_id al filtro
      handleChangeStorageFilters({
        ...storageFilters,
        brands: {
          brand_name: [],
          brand_id: [...storageFilters?.brands?.brand_id, filter.id],
          brandsTag: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });

      handleScrollTo();
    }
  }

  //DeliveryZone
  function handleChangeDeliveryZone(filter, reset = false) {
    //Reiniciamos filtro
    if (reset) {
      handleChangeStorageFilters({
        ...storageFilters,
        delivery_zones: {
          delivery_zone_name: [],
          delivery_zone_id: [],
          delivery_zone_tag: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });

      setSearchParamInputDeliveryZone("");
      setDeliveryZonesFilter(
        listProductsGridsFilters?.regions.sort((a, b) => a.name.localeCompare(b.name))
      );
      handleScrollTo();
      return;
    }

    //Quitamos delivery_zone_id del filtro
    if (storageFilters?.delivery_zones?.delivery_zone_id.includes(filter.id)) {
      handleChangeStorageFilters({
        ...storageFilters,
        delivery_zones: {
          delivery_zone_name: removeItemFromArr(storageFilters.delivery_zones?.delivery_zone_name, filter.name),
          delivery_zone_id: removeItemFromArr(storageFilters.delivery_zones?.delivery_zone_id, filter.id),
          delivery_zone_tag: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });
    } else { //Agregamos delivery_zone_id al filtro
      handleChangeStorageFilters({
        ...storageFilters,
        delivery_zones: {
          delivery_zone_name: [],
          delivery_zone_id: [...storageFilters?.delivery_zones?.delivery_zone_id, filter.id],
          delivery_zone_tag: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });

      handleScrollTo();
    }
  }

  //Price & Delivery Days function
  const handleClickViewResult = (type, reset = false) => {
    if (reset) {

      if (type === "price") {
        setPriceMax("");
        setPriceMin("");
      }
      if (type === "delivery_days") {
        setDeliveryDaysMax(0);
        setDeliveryDaysMin(0);
      }

      setCurrencyCode(null);

      handleChangeStorageFilters({
        ...storageFilters,
        price:
          type === "price"
            ? []
            : [{ min: priceMin, max: priceMax }],
        delivery_days:
          type === "delivery_days"
            ? []
            : [{ min: deliveryDaysMin, max: deliveryDaysMax }],
        currency_code: null,
        isFirstLoad: false,
      });

      handleScrollTo();
      return;
    }

    if (type === "price") {
      handleChangeStorageFilters({
        ...storageFilters,
        price: [{ min: priceMin, max: priceMax }],
        currency_code: currencyCode,
        isFirstLoad: false,
        resetPage: true,
      });
    }

    if (type === "delivery_days") {
      handleChangeStorageFilters({
        ...storageFilters,
        delivery_days: [
          {
            min: deliveryDaysMin,
            max: deliveryDaysMax,
          },
        ],
        currency_code: currencyCode,
        isFirstLoad: false,
        resetPage: true,
      });
    }

    handleScrollTo();
  };

  //Provider
  function handleChangeProvider(filter, reset = false) {

    //Reiniciamos filtro
    if (reset) {
      handleChangeStorageFilters({
        ...storageFilters,
        providers: {
          provider_name: [],
          provider_id: [],
          providersTag: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });

      if (listProductsGridsFilters?.providers && listProductsGridsFilters?.providers.length > 0) {
        const formattedProviders = listProductsGridsFilters?.providers.map(provider => (
          { name: provider?.fantasy_name, id: provider?.id }
        ))
        setProvidersFilter(formattedProviders);
      }
      setSearchParamInputProviders("");
      handleScrollTo();
      return;
    }

    //Quitamos provider_id del filtro
    if (storageFilters?.providers && storageFilters?.providers?.provider_id.includes(filter.id)) {
      handleChangeStorageFilters({
        ...storageFilters,
        providers: {
          provider_id: removeItemFromArr(storageFilters.providers?.provider_id, filter.id),
        },
        isFirstLoad: false,
        resetPage: true,
      });
    } else { //Agregamos provider_id al filtro
      handleChangeStorageFilters({
        ...storageFilters,
        providers: {
          provider_id: [...storageFilters?.providers?.provider_id, filter.id],
        },
        isFirstLoad: false,
        resetPage: true,
      });

      handleScrollTo();
    }
  }

  //ProviderType
  function handleChangeProvidersType(filter, reset = false) {
    //Reiniciamos filtro
    if (reset) {
      handleChangeStorageFilters({
        ...storageFilters,
        providers_type: {
          provider_type_name: [],
          provider_type_id: [],
        },
        isFirstLoad: false,
        resetPage: true,
      });


      handleScrollTo();
      return;
    }

    //Quitamos provider_type_id del filtro
    if (storageFilters?.providers_type?.provider_type_id.includes(filter.id)) {
      handleChangeStorageFilters({
        ...storageFilters,
        providers: {
          provider_type_name: removeItemFromArr(storageFilters?.providers_type?.provider_type_name, filter.name),
          provider_type_id: removeItemFromArr(storageFilters?.providers_type?.provider_type_id, filter.id),
        },
        isFirstLoad: false,
        resetPage: true,
      });
    } else { //Agregamos provider_type_id al filtro
      handleChangeStorageFilters({
        ...storageFilters,
        providers: {
          provider_type_name: [],
          provider_type_id: [...storageFilters?.providers_type?.provider_type_id, filter.id],
        },
        isFirstLoad: false,
        resetPage: true,
      });

      handleScrollTo();
    }
  }

  function sanitizedPrices(value) {
    // Remove non-numeric characters except for the first decimal point
    const sanitizedValue = value.replace(/[^0-9.,]/g, "")
      .replace(/([\.,].*)([\.,])/g, '$1');

    // Replace comma with dot for internal representation
    const normalizedValue = sanitizedValue.replace(",", ".");

    return normalizedValue;
  }

  const handleClickCleanFilters = () => {
    handleChangeStorageFilters(INITIAL_EMPTY_FILTERS);
    handleGetFilters();
    handleScrollTo();
    setGlobalStorage("reset");
  };

  const handleToggleFilterModal = () => {
    setToggleFilterModal(!toggleFilterModal)
  }

  const handleChangeDeliveryZoneSearchParam = async (value) => {
    const filteredDeliveryZones = await listProductsGridsFilters?.regions.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    const sortedDeliveryZones = filteredDeliveryZones.length > 0
      ? filteredDeliveryZones.sort((a, b) => a.name.localeCompare(b.name))
      : listProductsGridsFilters?.regions.sort((a, b) => a.name.localeCompare(b.name));

    setDeliveryZonesFilter(sortedDeliveryZones);
    setSearchParamInputDeliveryZone(value);
  };

  const handleChangeProviderSearchParam = async (value) => {
    const filteredProviders = await listProductsGridsFilters?.providers?.filter(item =>
      item.fantasy_name.toLowerCase().includes(value.toLowerCase())
    );

    const sortedProviders = filteredProviders.length > 0
      ? filteredProviders.sort((a, b) => a.fantasy_name.localeCompare(b.fantasy_name))
      : listProductsGridsFilters?.providers.sort((a, b) => a.fantasy_name.localeCompare(b.fantasy_name));

    if (sortedProviders.length > 0) {
      const formattedProviders = sortedProviders.map(provider => (
        { name: provider?.fantasy_name, id: provider?.id }
      ))
      setProvidersFilter(formattedProviders);
      setSearchParamInputProviders(value);
    }
  };

  // LISTA DE CATEGORIAS -> listProductsGridsFilters.categories

  return (
    <ErrorBoundary fallback={<p>Filtros no encontrados</p>}>
      <Box mb={"4.625rem"}>
        {
          skeletonLoading ? (
            <Flex flexDir={"column"} justifyContent={"center"}>
              <SkeletonText noOfLines={1} spacing="2" width={"100%"} mt={"4"} />
            </Flex>
          ) : (
            <Flex
              gap={"0.5rem"}
            >
              {/* CATEGORY FILTER */}
              {categoriesWParents && categoriesWParents?.length > 0 &&
                <Flex mt={4} zIndex={9999}>
                  <MenuFilter
                    name={"Categoría"}
                    righticonone={
                      storageFilters?.categories
                        && storageFilters?.categories?.category_id.length > 0 ? arrowPrimary : arrowDark
                    }
                    righticontwo={arrowLight}
                    menuListBorderRadius={"0.75rem"}
                    style={{
                      border: storageFilters?.categories
                        && storageFilters?.categories?.category_id.length > 0
                        ? "0.125rem solid #64247E"
                        : "unset",
                    }}
                    customFontWeightText={
                      storageFilters?.categories
                        && storageFilters?.categories?.category_id.length > 0
                        ? "500"
                        : "300"
                    }
                    customColorText={
                      Boolean(storageFilters?.categories
                        && storageFilters?.categories?.category_id.length > 0)
                    }
                  >
                    <ErrorBoundary fallback={<p>Categorías no encontradas</p>}>
                      <>
                        <Grid className={styles.grid} px={7}>
                          <CheckboxBekeuInterminate
                            categoriesWParents={categoriesWParents}
                            handleChangeCollapseIndex={handleChangeCollapseIndex}
                            collapseIndex={collapseIndex}
                            handleChangeFilters={handleChangeFilters}
                          />
                        </Grid>
                        <Box px={7}>
                          <ButtonBekeuOutline
                            onClick={() => handleChangeFilters("", true)}
                            className="animate__animated animate__fadeIn"
                            leftIcon={<Img src={trashIcon} alt="trash_icon" />}
                            height={"2.375rem"}
                            p={"0.75rem"}
                            mt={2}
                          >
                            Limpiar filtros
                          </ButtonBekeuOutline>
                        </Box>
                      </>
                    </ErrorBoundary>
                  </MenuFilter>
                </Flex>
              }

              {/* BRAND FILTER */}
              {listProductsGridsFilters?.brands && listProductsGridsFilters?.brands.length > 0 &&
                <Flex mt={4}>
                  <MenuFilter
                    name={"Marca"}
                    righticonone={
                      storageFilters?.brands
                        && storageFilters?.brands?.brand_id.length > 0 ? arrowPrimary : arrowDark
                    }
                    righticontwo={arrowLight}
                    menuListBorderRadius={"0.75rem"}
                    style={{
                      border: storageFilters?.brands
                        && storageFilters?.brands?.brand_id.length > 0
                        ? "0.125rem solid #64247E"
                        : "unset"
                    }}
                    customFontWeightText={
                      storageFilters?.brands
                        && storageFilters?.brands?.brand_id.length > 0
                        ? "500"
                        : "300"
                    }
                    customColorText={
                      Boolean(storageFilters?.brands
                        && storageFilters?.brands?.brand_id.length > 0)
                    }
                  >
                    <ErrorBoundary fallback={<p>Marcas no encontradas</p>}>
                      <>
                        <Grid className={styles.grid} px={7}>
                          <CheckboxBekeuInterminate
                            brands={listProductsGridsFilters?.brands}
                            handleChangeFilters={handleChangeBrand}
                          />
                        </Grid>
                        <Box px={7}>
                          <ButtonBekeuOutline
                            onClick={() => handleChangeBrand("", true)}
                            className="animate__animated animate__fadeIn"
                            leftIcon={<Img src={trashIcon} alt="trash_icon" />}
                            height={"2.375rem"}
                            p={"0.75rem"}
                            mt={2}
                          >
                            Limpiar filtros
                          </ButtonBekeuOutline>
                        </Box>
                      </>
                    </ErrorBoundary>
                  </MenuFilter>
                </Flex>
              }

              {/* PRICE FILTER */}
              {((listProductsGridsFilters?.currency_code && listProductsGridsFilters?.currency_code.length > 0)
                || (productsGridState?.products && productsGridState?.products.length > 0)) &&
                <Flex mt={4}>
                  <MenuFilter
                    name={"Precio"}
                    righticonone={((storageFilters?.price
                      && (storageFilters?.price[0]?.min >= 0 || storageFilters?.price[0]?.max >= 0))
                      || currencyCode !== null) ? arrowPrimary : arrowDark}
                    righticontwo={arrowLight}
                    menuListBorderRadius={"0.75rem"}
                    style={{
                      border: (storageFilters?.price
                        && (storageFilters?.price[0]?.min >= 0 || storageFilters?.price[0]?.max >= 0))
                        || currencyCode !== null
                        ? "0.125rem solid #64247E"
                        : "unset"
                    }}
                    customFontWeightText={
                      ((storageFilters?.price
                        && (storageFilters?.price[0]?.min >= 0 || storageFilters?.price[0]?.max >= 0))
                        || currencyCode !== null) ? "500" : "300"
                    }
                    customColorText={
                      Boolean(((storageFilters?.price
                        && (storageFilters?.price[0]?.min >= 0 || storageFilters?.price[0]?.max >= 0))
                        || currencyCode !== null))
                    }
                  >
                    <ErrorBoundary fallback={<p>Precios no encontradas</p>}>
                      <Grid px={4} gap={4}>

                        <GridItem className={styles.grid}>
                          <Flex w="100%" gap={1} flexDir={"column"}>
                            <Flex gap={2} alignItems="center">
                              <Flex alignItems="center" gap={2}>
                                <InputBekeu
                                  grouph="2.375rem"
                                  w={"6.5938rem"}
                                  height="2rem"
                                  borderRadius={"0.75rem"}
                                  fontSize="0.875rem"
                                  placeholder={"Min."}
                                  value={priceMin}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleClickViewResult("price");
                                    }
                                  }}
                                  onChange={(e) =>
                                    setPriceMin(sanitizedPrices(e.target.value))
                                  }
                                />
                                <Text color="brand.border">-</Text>
                              </Flex>
                              <Flex alignItems="center" gap={2}>
                                <InputBekeu
                                  grouph="2.375rem"
                                  w={"6.5938rem"}
                                  height="2rem"
                                  borderRadius={"0.75rem"}
                                  fontSize="0.875rem"
                                  placeholder={"Max."}
                                  value={priceMax}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleClickViewResult("price");
                                    }
                                  }}
                                  onChange={(e) =>
                                    setPriceMax(sanitizedPrices(e.target.value))
                                  }
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </GridItem>

                        <GridItem>
                          <Text fontSize={"0.75rem"} color={"brand.contentPrimary"}>1 USD = {productsGridState?.currentQuote} ARS</Text>
                        </GridItem>

                        {listProductsGridsFilters?.currency_code && listProductsGridsFilters?.currency_code.length > 0 && (
                          <GridItem>
                            <Stack direction={"row"} alignItems={"center"} cursor="pointer">
                              {CURRENCY_CODES.map(code => {
                                return (
                                  <CheckboxBekeu
                                    key={`currency-${code.id}`}
                                    fontSize={"1rem"}
                                    checked={currencyCode === code.value}
                                    onChange={() => {
                                      setCurrencyCode(code.value)
                                    }}
                                    size={"1.5rem"}
                                    m={"0 0 0 0.5"}
                                  >
                                    {code?.name}
                                  </CheckboxBekeu>
                                )
                              })
                              }
                            </Stack>
                          </GridItem>
                        )}

                        <Stack gap={0.5} mt={1}>
                          <ButtonBekeu
                            onClick={() => handleClickViewResult("price")}
                            className="animate__animated animate__fadeIn"
                            height={"2.375rem"}
                            p={"0.75rem"}
                            m={0}
                          >
                            Aceptar
                          </ButtonBekeu>

                          <ButtonBekeuOutline
                            onClick={() => handleClickViewResult("price", true)}
                            className="animate__animated animate__fadeIn"
                            leftIcon={<Img src={trashIcon} alt="trash_icon" />}
                            height={"2.375rem"}
                            p={"0.75rem"}
                          >
                            Limpiar filtros
                          </ButtonBekeuOutline>
                        </Stack>

                      </Grid>
                    </ErrorBoundary>
                  </MenuFilter>
                </Flex>
              }

              {/* DELIVERY ZONE FILTER */}
              {listProductsGridsFilters?.regions && listProductsGridsFilters?.regions.length > 0 &&
                <Flex mt={4}>
                  <MenuFilter
                    name={"Zona de entrega"}
                    righticonone={
                      storageFilters?.delivery_zones
                        && storageFilters?.delivery_zones?.delivery_zone_id.length > 0 ? arrowPrimary : arrowDark
                    }
                    righticontwo={arrowLight}
                    menuListBorderRadius={"0.75rem"}
                    style={{
                      border: storageFilters?.delivery_zones
                        && storageFilters?.delivery_zones?.delivery_zone_id.length > 0
                        ? "0.125rem solid #64247E"
                        : "unset"
                    }}
                    customFontWeightText={
                      storageFilters?.delivery_zones
                        && storageFilters?.delivery_zones?.delivery_zone_id.length > 0
                        ? "500"
                        : "300"
                    }
                    customColorText={
                      Boolean(storageFilters?.delivery_zones
                        && storageFilters?.delivery_zones?.delivery_zone_id.length > 0)
                    }
                  >
                    <Box p={4}>
                      <InputBekeu
                        placeholder={"Buscar"}
                        bg={"white"}
                        righticon={<Img src={search} mr={4} />}
                        value={searchParamInputDeliveryZone}
                        onChange={(e) => handleChangeDeliveryZoneSearchParam(e.target.value)}
                      />
                    </Box>
                    <ErrorBoundary fallback={<p>Zonas de entrega no encontradas</p>}>
                      <>
                        <Grid className={styles.grid} px={7}>
                          <CheckboxBekeuInterminate
                            deliveryZones={deliveryZonesFilter}
                            handleChangeFilters={handleChangeDeliveryZone}
                          />
                        </Grid>

                        <Box px={7}>
                          <ButtonBekeuOutline
                            onClick={() => handleChangeDeliveryZone("", true)}
                            className="animate__animated animate__fadeIn"
                            leftIcon={<Img src={trashIcon} alt="trash_icon" />}
                            height={"2.375rem"}
                            p={"0.75rem"}
                            mt={2}
                          >
                            Limpiar filtros
                          </ButtonBekeuOutline>
                        </Box>
                      </>
                    </ErrorBoundary>
                  </MenuFilter>
                </Flex>
              }

              {/* DELIVERY DAYS FILTER TODO: enable it when backend functionallity is done  */}
              {/* {deliveryDaysValidation && (
                <Flex mt={4}>
                  <MenuFilter
                    name={"Tiempo de entrega"}
                    righticonone={arrowDark}
                    righticontwo={arrowLight}
                  >
                    <ErrorBoundary fallback={<p>Tiempos de entrega no encontradas</p>}>
                      <>
                        <Grid className={styles.grid} px={7}>
                          <Flex w="100%" gap={1} flexDir={"column"} mt={1}>
                            <Flex alignItems="center">
                              <Text
                                fontSize={"0.875rem"}
                                w="50%"
                                color={"brand.contentSecondary"}
                              >
                                Mínimo
                              </Text>
                              <Text
                                fontSize={"0.875rem"}
                                w="50%"
                                color={"brand.contentSecondary"}
                              >
                                Máximo
                              </Text>
                            </Flex>
                            <Flex gap={2} alignItems="center">
                              <Flex alignItems="center" gap={2}>
                                <InputBekeu
                                  grouph="2.375rem"
                                  height="2.375rem"
                                  fontSize="0.875rem"
                                  placeholder={"Min."}
                                  value={deliveryDaysMin}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleClickViewResult("delivery_days");
                                    }
                                  }}
                                  onChange={(e) =>
                                  setDeliveryDaysMin(parseInt(e.target.value.replace(/[^0-9,.]/g, "o")) || 0)
                                  }
                                />
                                <Text color="brand.border">-</Text>
                              </Flex>
                              <Flex alignItems="center" gap={2}>
                                <InputBekeu
                                  grouph="2.375rem"
                                  height="2.375rem"
                                  fontSize="0.875rem"
                                  placeholder={
                                    listProductsGridsFilters.delivery_time.max
                                  }
                                  value={deliveryDaysMax}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleClickViewResult("delivery_days");
                                    }
                                  }}
                                  onChange={(e) =>
                                  setDeliveryDaysMax(parseInt(e.target.value.replace(/[^0-9,.]/g, "o")) || 0)
                                  }
                                />
                                <BekeuIcon
                                  src={checkPrimary}
                                  h="1.5rem"
                                  w="1.5rem"
                                  onClick={() => handleClickViewResult("delivery_days")}
                                  cursor="pointer"
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </Grid>

                        <Box px={7}>
                          <ButtonBekeuOutline
                            onClick={() => handleClickViewResult("delivery_days", true)}
                            className="animate__animated animate__fadeIn"
                            leftIcon={<Img src={trashIcon} alt="trash_icon" />}
                            height={"2.375rem"}
                            p={"0.75rem"}
                            mt={2}
                          >
                            Limpiar filtros
                          </ButtonBekeuOutline>
                        </Box>
                      </>
                    </ErrorBoundary>
                  </MenuFilter>
                </Flex>
              )} */}

              {/* PROVIDER FILTER */}
              {providersFilter && providersFilter.length > 0 &&
                <Flex mt={4}>
                  <MenuFilter
                    name={"Proveedor"}
                    righticonone={
                      storageFilters?.providers
                        && storageFilters?.providers?.provider_id.length > 0 ? arrowPrimary : arrowDark
                    }
                    righticontwo={arrowLight}
                    menuListBorderRadius={"0.75rem"}
                    style={{
                      border: storageFilters?.providers
                        && storageFilters?.providers?.provider_id.length > 0
                        ? "0.125rem solid #64247E"
                        : "unset"
                    }}
                    customFontWeightText={
                      storageFilters?.providers
                        && storageFilters?.providers?.provider_id.length > 0
                        ? "500"
                        : "300"
                    }
                    customColorText={
                      Boolean(storageFilters?.providers
                        && storageFilters?.providers?.provider_id.length > 0)
                    }
                  >
                    <Box p={4}>
                      <InputBekeu
                        placeholder={"Buscar"}
                        bg={"white"}
                        righticon={<Img src={search} mr={4} />}
                        value={searchParamInputProviders}
                        onChange={(e) => handleChangeProviderSearchParam(e.target.value)}
                      />
                    </Box>
                    <ErrorBoundary fallback={<p>Proveedores no encontradas</p>}>
                      <>
                        <Grid className={styles.grid} px={7}>
                          <CheckboxBekeuInterminate
                            providers={providersFilter}
                            handleChangeFilters={handleChangeProvider}
                          />
                        </Grid>
                        <Box px={7}>
                          <ButtonBekeuOutline
                            onClick={() => handleChangeProvider("", true)}
                            className="animate__animated animate__fadeIn"
                            leftIcon={<Img src={trashIcon} alt="trash_icon" />}
                            height={"2.375rem"}
                            p={"0.75rem"}
                            mt={2}
                          >
                            Limpiar filtros
                          </ButtonBekeuOutline>
                        </Box>
                      </>
                    </ErrorBoundary>
                  </MenuFilter>
                </Flex>
              }

              {/* PROVIDER TYPE FILTER TODO: enable it when backend functionallity is done */}
              {/* {providersTypeFilter && providersTypeFilter.length > 0 &&
                <Flex mt={4}>
                  <MenuFilter
                    name={"Tipo de proveedor"}
                    righticonone={arrowDark}
                    righticontwo={arrowLight}
                  >
                    <ErrorBoundary fallback={<p>Tipos de proveedores no encontradas</p>}>
                      <>
                        <Grid className={styles.grid} px={7}>
                          <CheckboxBekeuInterminate
                            providersType={providersTypeFilter}
                            handleChangeFilters={handleChangeProvidersType}
                          />
                        </Grid>
                        <Box px={7}>
                          <ButtonBekeuOutline
                            onClick={() => handleChangeProvidersType("", true)}
                            className="animate__animated animate__fadeIn"
                            leftIcon={<Img src={trashIcon} alt="trash_icon" />}
                            height={"2.375rem"}
                            p={"0.75rem"}
                            mt={2}
                          >
                            Limpiar filtros
                          </ButtonBekeuOutline>
                        </Box>
                      </>
                    </ErrorBoundary>
                  </MenuFilter>
                </Flex>
              } */}

              {/* FILTER BUTTON */}
              {((productsGridState?.products && productsGridState?.products.length > 0) ||
                (listProductsGridsFilters?.currency_code && listProductsGridsFilters.currency_code.length > 0)) && (
                  <Flex mt={4}>
                    <Button
                      className="animate__animated animate__fadeIn"
                      style={{ border: totalFiltersSelected > 0 ? "0.125rem solid #64247E" : "unset" }}
                      rightIcon={<Img src={filterDark} alt="filter_dark_icon" />}
                      height={"2.375rem"}
                      color={"brand.primary"}
                      lineHeight="1.3619rem"
                      borderRadius={"0.9375rem"}
                      p={"0.5rem 0.75rem 0.5625rem 1rem"}
                      bg={"white"}
                      border={"0.0625rem solid rgba(189, 189, 189, 0.5)"}
                      fontWeight={500}
                      _focus={{
                        border:
                          "0.125rem solid #64247E",
                      }}
                      onClick={handleToggleFilterModal}
                    >
                      Filtros
                      <Text
                        width={"1rem"}
                        height={"1rem"}
                        position={"absolute"}
                        top={"-0.4375rem"}
                        right={"-0.4375rem"}
                        borderRadius={"100%"}
                        bg={"brand.acents"}
                        textAlign={"center"}
                        lineHeight={"normal"}
                        color={"brand.primary"}
                        fontSize={"0.75rem"}
                      >{totalFiltersSelected}</Text>
                    </Button>
                  </Flex>
                )}

              <FilterModalSummary
                storageFilters={storageFilters}
                handleChangeStorageFilters={handleChangeStorageFilters}
                toggle={toggleFilterModal}
                handleToggleFilterModal={handleToggleFilterModal}
                handleClickCleanFilters={handleClickCleanFilters}
                //deliveryTimeFilter ?
                //providers
                providersFilter={providersFilter}
                providersTypeFilter={providersTypeFilter}
                //categories
                categoriesFilter={listProductsGridsFilters?.categories}
                handleChangeCategoryFilters={handleChangeFilters}
                //brands
                brandsFilter={listProductsGridsFilters?.brands}
                handleChangeBrandFilters={handleChangeBrand}
                //price
                //providers
                handleChangeProviderFilters={handleChangeProvider}
                //delivery zones
                deliveryZonesFilter={listProductsGridsFilters?.regions}
                handleChangeDeliveryZoneFilters={handleChangeDeliveryZone}
                //providers type
                handleChangeProvidersTypeFilters={handleChangeProvidersType}
              />
            </Flex>
          )
        }
      </Box>
    </ErrorBoundary>
  );
};
