import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment";
import "moment/locale/es-mx";

import { Divider, Flex, Grid, GridItem, Text } from "@chakra-ui/react";

import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";

import { CartOrderId } from "../CartOrderId/CartOrderId";
import { ApproveCartsBadge } from "../ApproveCartsBadge/ApproveCartsBadge";
import { ApproveCartDetail } from "../ApproveCartDetail/ApproveCartDetail";

import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import * as cartActions from "../../pages/approveCartsPage/ducks/approveCarts.duck";

const ApproveCartOrdersItem = ({
  item,
  type,
  showitems,
  selected,
  state,
  getOneProduct,
  handleApproveCart,
  handleRejectCart,
  handleApproveOrder,
  handleRejectOrder,
  handlePagination,
  params,
  ...props
}) => {
  const [username, setUsername] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const authState = useSelector((state) => state?.auth);
  const history = useHistory();

  useEffect(() => {
    let parsedFullName = item?.client.user?.email;
    setUsername(parsedFullName);

    const total_items = item.buyOrders.reduce(
      (accumulator, buyOrder) => accumulator + buyOrder.items.length,
      0,
    );
    setTotalItems(total_items);
  }, [item.buyOrders, item?.client?.erp_username, item?.client.user?.email]);

  return (
    <BoxBekeu p={0} overflow={"unset"} {...props}>
      <Flex flexDir={"column"}>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          p="1.5rem"
          flexDir={["column", "column", "row", "row"]}
          gap={4}
        >
           <Flex gap={2} flexDir={["column", "row"]}>
              <H6 fontWeight={600}>Fecha de compra:</H6>
              <Text fontSize={"1.125rem"}>
                {moment(item?.created_at).format("DD/MM/YYYY")}
              </Text>
          </Flex>
          <Flex flexDir={["column", "column", "row", "row"]} gap={4}>
            <CartOrderId>Nº de carrito: {item?.internal_code}</CartOrderId>
            <ApproveCartsBadge item={item}>
              {item?.state === "1"
                ? "Pendiente de aprobación"
                : item?.state === "2"
                  ? "Entrega en proceso"
                  : item?.state === "3"
                    ? "Rechazada"
                    : item?.state === "4"
                      ? "Finalizada"
                      : "En curso"}
            </ApproveCartsBadge>
          </Flex>
        </Flex>
        <Divider />
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ]}
          justifyContent={"space-between"}
          p={"1.5rem"}
          gap={3}
        >
          <GridItem flexDir={"column"}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem" mb={3}>
              Comprador
            </Paragraphs>
            <Paragraphs lineHeight="1.5rem">{username}</Paragraphs>
          </GridItem>

          <GridItem flexDir={"column"}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem" mb={3}>
              Dirección de entrega
            </Paragraphs>
            <Paragraphs lineHeight="1.5rem">{item?.address?.name}</Paragraphs>
          </GridItem>

          <GridItem flexDir={"column"}>
            <Paragraphs fontWeight={600} lineHeight="1.5rem" mb={3}>
              Comentarios del comprador
            </Paragraphs>
            <Paragraphs lineHeight="1.5rem">
              {item?.approver_comments}
            </Paragraphs>
          </GridItem>
        </Grid>
        <Divider />

        <ApproveCartDetail
          item={item}
          showitems={false}
          totalItems={totalItems}
          auth={authState}
        />

      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(
  connect(null, { ...product.actions, ...cartActions.actions })(
    ApproveCartOrdersItem,
  ),
);
