import React from 'react';
import {
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Img,
  Box,
  useToast,
  OrderedList,
  ListItem,
  Divider,
} from "@chakra-ui/react";
import smsTracking from "../../design-system/icons/Contact/sms-tracking.svg";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { actions as cartActions } from "../../store/cart/cart.duck";
import { useEffect } from "react";
import { useState } from "react";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { useHistory } from "react-router-dom";
import errorCircle from "../../design-system/icons/Errors/error-circle.svg";
import successsvg from "../../design-system/icons/Arrows/success-circle-fill.svg";

import useFeatures from '../../hooks/useFeatures';

const CartSuccessModal = ({
  isDisabled,
  handleEndsBuy,
  cart,
  getCart,
  address,
  ...props
}) => {
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const [orderData, setOrderData] = useState({
  //   cartId: cart?.id,
  //   totalARS: cart?.total_ARP,
  //   totalUSD: cart?.total_USD,
  //   internalCode: cart?.internal_code,
  //   shipping_cost_ARS: cart?.shipping_cost_ARP,
  //   shipping_cost_USD: cart?.shipping_cost_USD,
  //   total_w_shipping_ARS: cart?.total_w_shipping_ARP,
  //   total_w_shipping_USD: cart?.total_w_shipping_USD,
  // });

  const cartState = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);

  const { imputationUser, companyApprovalsUser, sapUser } = useFeatures();

  const handleClickButton = () => {
    handleEndsBuy();
  };

  // useEffect(() => {
  //   cart?.id &&
  //     setOrderData({
  //       cartId: cart?.id,
  //       totalARS: cart?.total_ARP,
  //       totalUSD: cart?.total_USD,
  //       internalCode: cart?.internal_code,
  //       shipping_cost_ARS: cart?.shipping_cost_ARP,
  //       shipping_cost_USD: cart?.shipping_cost_USD,
  //       total_w_shipping_ARS: cart?.total_w_shipping_ARP,
  //       total_w_shipping_USD: cart?.total_w_shipping_USD,
  //     });
  // }, [cart]);

  useEffect(() => {
    setLoading(cartState?.loading || false);
  }, [cartState?.loading])

  useEffect(() => {
    if (
      cartState?.endsBuyResultValue &&
      Object.values(cartState?.endsBuyResultValue).length > 0
    ) {
      return onOpen();
    }

  }, [cartState.endsBuyResultValue]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  const arrayProduct = [];

  const handleErrorEndBuy = () => {
    handleScrollTo();
    cart.items.map(item => arrayProduct.push(item?.error));
    if (arrayProduct.includes(true)) {
      toast({
        title: 'Error al finalizar compra',
        description:
          'No se puede generar orden de compra con productos fuera de stock',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      handleClickButton();
    }
  };

  return (
    <>
      {imputationUser ? (
        <ButtonBekeu
          onClick={handleErrorEndBuy}
          isDisabled={isDisabled}
          isLoading={loading && !cartState?.endsBuyResult}
        >
          Finalizar compra
        </ButtonBekeu>
      ) : (
        <ButtonBekeu
          onClick={handleErrorEndBuy}
          isDisabled={!address}
          isLoading={loading && !cartState?.endsBuyResult}
        >
          Finalizar compra
        </ButtonBekeu>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => {
          cartState?.endsBuyResultValue?.status !== 'fail' &&
            props.fillCart([]);
          props?.endsBuyResultValue({});
          history.push('/');
        }}
        size={'xl'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={'0.9375rem'} minW="33rem">
          <ModalCloseButton _focus={{ boxShadow: 'rgba(0,0,0,0)' }} />
          <ModalBody borderRadius={'0.9375rem'} p={'3rem'}>
            <Flex
              flexDir={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {/* Success */}
              {!cartState?.endsBuyResultValue?.status?.error &&
              cartState?.endsBuyResultValue?.status !== 'fail' ? (
                <>
                  {!companyApprovalsUser ? (
                    <Flex
                      flexDir={'column'}
                      justifyContent="center"
                      alignItems="center"
                      gap={6}
                      mb={6}
                    >
                      <Img src={successsvg} />
                      <H5 textAlign="center">
                        ¡Tu compra fue realizada con éxito!
                      </H5>

                      <Paragraphs
                        fontWeight={600}
                        fontSize={'1rem'}
                        lineHeight="1.3619rem"
                        color={'brand.success'}
                      >
                        Carrito Nº {cartState?.items?.internal_code}
                      </Paragraphs>
                    </Flex>
                  ) : (
                    <>
                      <Flex alignItems={'center'} my={6}>
                        <Img src={smsTracking} alt="smsTracking" />
                      </Flex>
                      <H5 mb={4} textAlign={'center'}>
                        ¡Tu compra fue enviada a aprobación!
                      </H5>
                      {cartState?.endsBuyResultValue?.message?.data
                        ?.approver && (
                        <Box
                          color={'brand.primary'}
                          bg={'rgba(100, 36, 126, 0.1)'}
                          px={2}
                          borderRadius={'0.75rem'}
                          w={'fit-content'}
                          maxW={'22rem'}
                          mb={4}
                          textAlign={'center'}
                          lineHeight="1.3619rem"
                        >
                          {`Aprobador: ${cartState?.endsBuyResultValue?.message?.data?.approver}`}
                        </Box>
                      )}
                        <Paragraphs
                          fontWeight={600}
                          fontSize={'1rem'}
                          color={'brand.success'}
                          mt={4}
                          lineHeight="1.3619rem"
                          alignSelf={"unset"}
                        >
                          Compra N° {cartState?.items?.internal_code}
                        </Paragraphs>
                    </>
                  )}
                </>
              ) : ( 
                // Error
                <>
                  <Flex alignItems={"center"} mb={6}>
                    <Img src={errorCircle} alt="error" />
                  </Flex>
                  <H5 mb={4}>Algo salió mal</H5>
                  <Paragraphs align="center" lineHeight="1.375rem" mb={4}>
                    Error al finalizar compra.
                  </Paragraphs>
                </>
              )}
            </Flex>

            {sapUser && (
              <>                
                <Flex
                  flexDir={'column'}
                  justifyContent={'space-between'}
                  gap="1rem"
                >
                  {cartState?.endsBuyResultValue &&
                    Object.entries(cartState?.endsBuyResultValue).length > 0 &&
                    cartState?.endsBuyResultValue?.message?.success !== null &&
                      <>
                        <Divider mt={4} mb={6} />
                        {cartState?.endsBuyResultValue?.message?.success
                          .map((success) => {
                            const successNumber = Object.keys(success)[0];
                            const sapMsg =
                            cartState?.endsBuyResultValue?.message?.data
                              .internal_code;

                          const sapNumber = sapMsg?.replace(/^\D+/g, '');

                          return (
                            <Flex
                              flexDir={'column'}
                              key={Math.random()}
                            >
                              <Paragraphs
                                fontWeight={600}
                                fontSize={'1rem'}
                                color={'brand.success'}
                                lineHeight="1.3619rem"
                              >
                                Compra Nº {successNumber}
                              </Paragraphs>
                              <Paragraphs
                                fontSize={'1rem'}
                                lineHeight="1.3619rem"
                                alignSelf={'flex-start'}
                              >
                                {sapNumber?.length > 0 &&
                                  `Creada con el N° de pedido SAP ${sapNumber}`}
                              </Paragraphs>
                            </Flex>
                          );

                        })}
                      </>  
                  }
                  {cartState?.endsBuyResultValue?.status === 'fail' && (
                    <>
                      <Divider />

                      {cartState?.endsBuyResultValue &&
                        Object.entries(cartState?.endsBuyResultValue).length > 0 &&
                        cartState?.endsBuyResultValue?.message?.error !== null &&
                        cartState?.endsBuyResultValue?.message?.error.map((error) => {
         
                          const errorNumber = Object.keys(error)[0];
                          
                          return (
                            <Flex
                              flexDir={'column'}
                              key={Math.random()}
                            >
                              <Paragraphs
                                fontWeight={600}
                                fontSize={'1rem'}
                                color="brand.error"
                                lineHeight="1.3619rem"
                                mb={3}
                              >
                                Carrito Nº {errorNumber}
                              </Paragraphs>
                              <Paragraphs fontWeight={600} fontSize={'1rem'} lineHeight="1.3619rem" mb={1}>
                                La compra no pudo realizarse por los siguientes
                                motivos:
                              </Paragraphs>
                              <Paragraphs>
                                <OrderedList>
                                  {Object.values(error)[0].map((item, index) => (
                                    <>
                                      <ListItem key={`listItem-${index}`} fontSize={"1rem"} lineHeight="1.3619rem">{item}</ListItem>
                                    </>
                                  ))}
                                </OrderedList>
                              </Paragraphs>
                            </Flex>
                          );
                        })}
                    </>
                  )}
                </Flex>

              </>
            )}

            <Divider mt={4} mb={6} />

            <Flex gap="1.5rem">
              <ButtonBekeuOutline
                onClick={() => {
                  cartState?.endsBuyResultValue?.status !== 'fail' &&
                    props.fillCart([]);
                  props?.endsBuyResultValue({});
                  history.push('/');
                }}
              >
                Volver al home
              </ButtonBekeuOutline>
              <ButtonBekeu
                my={0}
                onClick={() => {
                  cartState?.endsBuyResultValue?.status !== 'fail' &&
                    props.fillCart([]);
                  props?.endsBuyResultValue({});
                  history.push('/my-orders/?page=1&per_page=20');
                }}
              >
                Ir a mis compras
              </ButtonBekeu>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default injectIntl(connect(null, cartActions)(CartSuccessModal));
