import React, { useEffect } from "react";

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  useDisclosure,
  Img,
  Text,
  Link,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import error503 from "../../design-system/icons/Errors/error-503.svg";

export const Error503Modal = ({customIsOpen, customOnClose}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    customIsOpen ? onOpen() : onClose();
  },[customIsOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent borderRadius={"0.75rem"}>
        <ModalHeader paddingY={7}>
          <ModalCloseButton
            _focus={{ boxShadow: "rgba(0,0,0,0)" }}
            onClick={() => customOnClose()}  
          />
        </ModalHeader>
        <ModalBody padding={"0 3rem 3rem 3rem"}>
          <Flex
            flexDir={"column"}
            justifyContent="center"
            alignItems="center"
            gap={6}
          >
            <Img src={error503} alt="error-503" />
            <H5 textAlign="center">Fuera de servicio</H5>
            <Paragraphs align="center" lineHeight="22px">
              El servidor no está disponible temporalmente.
              <br />
              Por favor, intentalo de nuevo en unos minutos.
            </Paragraphs>
            <Flex
              fontSize="0.85rem"
              justifyContent="space-between"
              width="100%"
            >
              <Flex alignItems="center" gap={1}>
                <Text>Código de error:</Text>
                <Text fontWeight="bold">503</Text>
              </Flex>
              <Link color="brand.primary" href="mailto:info@bekeu.com">
                Contactar a soporte
              </Link>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
