import { useState, useEffect, useRef } from "react"
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { actions as productsGridActions} from "../pages/productGridPage/ducks/productsGrid.duck";

import useQuery from "./useQuery";

import { INITIAL_STORAGED_FILTERS, INITIAL_EMPTY_FILTERS } from "../constants/constants";


export const useStorageFilters = () => {
  const [totalFiltersSelected, setTotalFiltersSelected] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); 

  const [checkedCategoryFilterItems, setCheckedCategoryFilterItems] = useState({});
  const [checkedBrandFilterItems, setCheckedBrandFilterItems] = useState({});
  const [checkedDeliveryZoneFilterItems, setCheckedDeliveryZoneFilterItems] = useState({});
  const [checkedProviderFilterItems, setCheckedProviderFilterItems] = useState({});
  const [checkedProvidersTypeFilterItems, setCheckedProvidersTypeFilterItems] = useState({});


  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  let storageFiltersLS = JSON.parse(localStorage.getItem("storageFilters")) || {};

  let page = query.get("page");
  let perPage = query.get("per_page");
  let category_id = query.get("category_id");
  let term = query.get("term");

  let categoryIdRef = useRef(category_id);
  let termRef = useRef(term);
  let pageRef = useRef(page);
  let perPageRef = useRef(perPage);

  useEffect(() => {
    const isRefreshed = !sessionStorage.getItem('visited');
    
    if (isRefreshed) {
      sessionStorage.setItem('visited', 'true');
    } else {
      handleGetFilters(true)
    }
  }, []);

  useEffect(() => {
    handleChangeStorageFilters();
  },[])

  useEffect(() => {    

    if(!location.pathname.includes("search")) return

    if(
      storageFiltersLS?.isFirstLoad
      || (category_id && categoryIdRef.current !== category_id)
      || (term && termRef.current !== term)
      || (page && pageRef.current !== page)
      || (perPage && perPageRef.current !== perPage)
    ){

      setCheckedCategoryFilterItems({});
      setCheckedBrandFilterItems({});
      setCheckedDeliveryZoneFilterItems({});
      setCheckedProviderFilterItems({});
      setCheckedProvidersTypeFilterItems({});   

      page && pageRef.current === page && handleGetFilters(true);

      pageRef.current = page;

      handleChangeStorageFilters({...storageFiltersLS, isFirstLoad: false});

    }

      
  }, [category_id, searchTerm, page, perPage])

  useEffect(() => {
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS;

    if(filtersLS && Object.values(filtersLS).length === 0) return;

    //----- START Get the selected filters totals -----//
      const filterCounts = {
        category: filtersLS?.categories?.category_id,
        brand: filtersLS?.brands?.brand_id,
        deliveryZone: filtersLS?.delivery_zones?.delivery_zone_id,
        provider: filtersLS?.providers?.provider_id,
        providersType: filtersLS?.providers_type?.provider_type_id,
      };
      
      const getFilterCount = (filterItems) => 
        Object.keys(filterItems || {}).length;
      
      const counts = Object.entries(filterCounts).reduce((acc, [key, items]) => {
        acc[`${key}Count`] = getFilterCount(items);
        return acc;
      }, {});
      
      const { 
        categoryCount, 
        brandCount, 
        deliveryZoneCount, 
        providerCount, 
        providersTypeCount 
      } = counts;

      const priceCount = filtersLS.price && filtersLS?.price.length > 0 ? 1 : 0;
      const deliveryDayCount = filtersLS?.delivery_days && filtersLS?.delivery_days.length > 0 ? 1 : 0;

      setTotalFiltersSelected(
        categoryCount +
          brandCount +
          deliveryZoneCount +
          providerCount +
          providersTypeCount +
          priceCount+
          deliveryDayCount
      );
    //----- END Get the selected filters totals -----//
  }, [storageFiltersLS])

  useEffect(() => {
    setSearchTerm(term);
  },[term])

  const handleChangeStorageFilters = (newFilters) => {
    localStorage.setItem('storageFilters', JSON.stringify(newFilters || JSON.parse(localStorage.getItem("storageFilters"))));
    handleMainSearch();
  }

  const handleMainSearch = () => {  
    
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS;

    if(filtersLS?.resetPage){
      const searchParams = new URLSearchParams(location.search); // Obtiene los parámetros de la URL actual
      searchParams.set("page", 1); // Modifica o agrega un nuevo parámetro
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(), // Actualiza la URL con los nuevos parámetros
      });
    }

    dispatch(
      productsGridActions?.listProductsGrid({
        term: term,
        page,
        perPage,
        isFirstLoad: filtersLS?.resetPage,
        category_id:
          filtersLS?.categories
            && filtersLS?.categories?.category_id.length > 0
            ? filtersLS?.categories?.category_id : category_id ? [category_id] : [],
        brand_id:
          filtersLS?.brands
            && filtersLS?.brands?.brand_id.length > 0
            ? filtersLS?.brands?.brand_id : [],
        delivery_zone:
          filtersLS?.delivery_zones
            && filtersLS?.delivery_zones?.delivery_zone_id.length > 0
            ? filtersLS?.delivery_zones?.delivery_zone_id : [],
        price: 
          filtersLS?.price
            && filtersLS?.price.length > 0 ? [filtersLS?.price[0]?.min, filtersLS?.price[0]?.max] : [],
        currency_code:
          filtersLS?.currency_code
            ? filtersLS?.currency_code : null,
        delivery_days:
          filtersLS?.delivery_time
            && filtersLS?.delivery_time?.min
            && filtersLS?.delivery_time?.max ? [filtersLS?.delivery_time?.min, filtersLS?.delivery_time?.max] : [],
        provider_id:
          filtersLS?.providers
              && filtersLS?.providers?.provider_id
              && filtersLS?.providers?.provider_id.length > 0
                ? filtersLS?.providers?.provider_id : [],
        provider_type_id:
          filtersLS?.providers_type
            && filtersLS?.providers_type?.provider_type_id
            && filtersLS?.providers_type?.provider_type_id.length > 0
              ? filtersLS?.providers_type?.provider_type_id : [],
        sort_by:
          filtersLS?.sort_by
            ? filtersLS?.sort_by : "",
      })
    )
  }

  const handleGetFilters = (reset = false) => {

    const filtersLS = reset ? INITIAL_EMPTY_FILTERS : JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS;

    dispatch(
      productsGridActions?.listProductsGridFilters({
        term: term,
        category_id:
          filtersLS?.categories
            && filtersLS?.categories?.category_id.length > 0
            ? filtersLS?.categories?.category_id : category_id ? [category_id] : [],
        brand_id:
          filtersLS?.brands
            && filtersLS?.brands?.brand_id.length > 0
            ? filtersLS?.brands?.brand_id : [],
        delivery_zone:
         filtersLS?.delivery_zones
            && filtersLS?.delivery_zones?.delivery_zone_id.length > 0
            ? filtersLS?.delivery_zones?.delivery_zone_id : [],
        price:
         filtersLS?.price
            && filtersLS?.price.length > 0 ? [filtersLS?.price[0]?.min, filtersLS?.price[0]?.max] : [],
        currency_code:
         filtersLS?.currency_code
            ? filtersLS?.currency_code : null,
        delivery_days:
         filtersLS?.delivery_time
            && filtersLS?.delivery_time?.min
            && filtersLS?.delivery_time?.max ? [filtersLS?.delivery_time?.min, filtersLS?.delivery_time?.max] : [],
        provider_id:
         filtersLS?.providers
            && filtersLS?.providers?.provider_id
            && filtersLS?.providers?.provider_id.length > 0
            ? filtersLS?.providers?.provider_id : [],
        provider_type_id:
         filtersLS?.providers_type
            && filtersLS?.providers_type?.provider_type_id
            && filtersLS?.providers_type?.provider_type_id.length > 0
            ? filtersLS?.providers_type?.provider_type_id : [],
        sort_by:
          filtersLS?.sort_by
            ? filtersLS?.sort_by : "",
      })
    )
  }

  //FILTER CHECKS
  const handleCheckedCategoryFilterItems = (item) => {
    setCheckedCategoryFilterItems(item)
  }

  const handleCheckedBrandFilterItems = (item) => {
    setCheckedBrandFilterItems(item)
  }

  const handleCheckedDeliveryZoneFilterItems = (item) => {
    setCheckedDeliveryZoneFilterItems(item)
  }

  const handleCheckedProviderFilterItems = (item) => {
    setCheckedProviderFilterItems(item)
  }

  const handleCheckedProvidersTypeFilterItems = (item) => {
    setCheckedProvidersTypeFilterItems(item)
  }
  
  return{
    //Variables
    page,
    perPage,
    category_id,
    searchTerm,
    
    storageFilters: storageFiltersLS,
    totalFiltersSelected,
    handleGetFilters,

    checkedCategoryFilterItems,
    checkedBrandFilterItems,
    checkedDeliveryZoneFilterItems,
    checkedProviderFilterItems,
    checkedProvidersTypeFilterItems,

    
    //Functions
    handleChangeStorageFilters,

    handleCheckedBrandFilterItems,
    handleCheckedCategoryFilterItems,
    handleCheckedDeliveryZoneFilterItems,
    handleCheckedProviderFilterItems,
    handleCheckedProvidersTypeFilterItems,
  }
}
